import { pathOr, pipe, propOr } from 'ramda';
import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

import { devices, Screen } from "../../constants";

type TextProps = {
  $alignItems?: CSSProperties['alignItems'];
  $background?: CSSProperties['background'];
  $border?: CSSProperties['border'];
  $borderBottom?: CSSProperties['borderBottom'];
  $borderColor?: CSSProperties['borderColor'];
  $borderRadius?: CSSProperties['borderRadius'];
  $color?: CSSProperties['color'];
  $cursor?: CSSProperties['cursor'];
  $darkColor?: CSSProperties['color'];
  $dash?: boolean;
  $display?: CSSProperties['display'];
  $errorLink?: boolean;
  $float?: CSSProperties['float'];
  $gap?: CSSProperties['gap'];
  $gridArea?: CSSProperties['gridArea'];
  $height?: CSSProperties['height'];
  $hoverBackground?: CSSProperties['background'];
  $hoverColor?: CSSProperties['color'];
  $htmlLineClamp?: number;
  $htmlLineClampNoP?: number;
  $hyphens?: CSSProperties['hyphens'];
  $innerPMarginBlock?: CSSProperties['marginBlock'];
  $innerPMarginBottom?: CSSProperties['marginBottom'];
  $innerPMarginTop?: CSSProperties['marginTop'];
  $justifyContent?: CSSProperties['justifyContent'];
  $letterSpacing?: CSSProperties['letterSpacing'];
  $lightColor?: CSSProperties['color'];
  $lineHeight?: CSSProperties['lineHeight'];
  $margin?: CSSProperties['margin'];
  $marginBottom?: CSSProperties['marginBottom'];
  $marginLeft?: CSSProperties['marginLeft'];
  $marginRight?: CSSProperties['marginTop'];
  $marginTop?: CSSProperties['marginTop'];
  $maxHeight?: CSSProperties['maxHeight'];
  $maxWidth?: CSSProperties['maxWidth'];
  $newsItemText?: 'visible' | 'hidden';
  $newsItemTitle?: string;
  $opacity?: CSSProperties['opacity'];
  $padding?: CSSProperties['padding'];
  $position?: CSSProperties['position'];
  $size?: number;
  $spaceBetweenParagraph?: boolean;
  $spanBackground?: CSSProperties['background'];
  $spanColor?: CSSProperties['color'];
  $textAlign?: CSSProperties['textAlign'];
  $textShadow?: CSSProperties['textShadow'];
  $textTransform?: CSSProperties['textTransform'];
  $textWithLink?: boolean;
  $transition?: CSSProperties['transition'];
  $unit?: 'px' | 'rem' | '%';
  $weight?: number;
  $whiteSpace?: CSSProperties['whiteSpace'];
  $width?: CSSProperties['width'];
};

type Props = TextProps & { [key in Screen]?: TextProps };

export const Text = styled.span<Props>`
  font-size: ${propOr(1, '$size')}${propOr('rem', '$unit')};
  line-height: ${propOr(1.07, '$lineHeight')}${propOr('rem', '$unit')};
  font-weight: ${propOr('normal', '$weight')};
  color: ${propOr(null, '$color')};
  margin: ${propOr(null, '$margin')};
  cursor: ${propOr(null, '$cursor')};
  margin-bottom: ${propOr(null, '$marginBottom')};
  margin-top: ${propOr(null, '$marginTop')};
  margin-right: ${propOr(null, '$marginRight')};
  margin-left: ${propOr(null, '$marginLeft')};
  letter-spacing: ${propOr(null, '$letterSpacing')};
  text-shadow: ${propOr(null, '$textShadow')};
  opacity: ${propOr(null, '$opacity')};
  text-align: ${propOr(null, '$textAlign')};
  max-height: ${propOr(null, '$maxHeight')};
  max-width: ${propOr(null, '$maxWidth')};
  text-transform: ${propOr(null, '$textTransform')};
  border: ${propOr(null, '$border')};
  border-bottom: ${propOr(null, '$borderBottom')};
  border-color: ${propOr(null, '$borderColor')};
  border-radius: ${propOr(null, '$borderRadius')};
  position: ${propOr(null, '$position')};
  background: ${propOr(null, '$background')};
  padding: ${propOr(null, '$padding')};
  grid-area: ${propOr(null, '$gridArea')};
  display: ${propOr(null, '$display')};
  align-items: ${propOr(null, '$alignItems')};
  justify-content: ${propOr(null, '$justifyContent')};
  white-space: ${propOr(null, '$whiteSpace')};
  float: ${propOr(null, '$float')};
  hyphens: ${propOr(null, '$hyphens')};
  gap: ${propOr(null, '$gap')};
  width: ${propOr(null, '$width')};
  height: ${propOr(null, '$height')};

  ${(props) =>
    props.$dash &&
    css`
      & p::before {
        content: '---';
        letter-spacing: -0.265rem;
        margin-right: 0.65rem;
      }
    `}
  & p {
    margin-block: ${propOr(0, '$innerPMarginBlock')};
    margin-top: ${propOr(null, '$innerPMarginTop')};
    margin-bottom: ${propOr(null, '$innerPMarginBottom')};
  }

  ${(props) =>
    props.$errorLink &&
    css`
      border-bottom: 0.135rem solid var(--color-black);
      margin-bottom: -0.135rem;
    `}

  ${(props) =>
    props.$spaceBetweenParagraph &&
    css`
      p + p {
        padding: 30px 0 0;
      }
    `}

  ${(props) =>
    props.$hoverColor &&
    css`
      &:hover {
        color: ${propOr(null, '$hoverColor')};
      }
    `}

  ${(props) =>
    props.$hoverBackground &&
    css`
      &:hover {
        background: ${propOr(null, '$hoverBackground')};
      }
    `}

  ${(props) =>
    props.$spanColor &&
    css`
      span {
        color: ${propOr(null, '$spanColor')};
      }
    `}

  ${(props) =>
    props.$spanBackground &&
    css`
      span {
        background: ${propOr(null, '$spanBackground')};
        padding-right: 0.2rem;
        margin-right: 0.2rem;
      }
    `}


  ${(props) =>
    props.$newsItemTitle === 'main' &&
    css`
      &:hover {
        span {
          background: white;
          color: black;
          padding-right: 0.2rem;
          margin-right: 0.2rem;
        }
      }
    `}

  ${(props) =>
    props.$newsItemTitle === 'tab' &&
    css`
      &:hover {
        span {
          background: black;
          color: white;
          padding-right: 0.2rem;
          margin-right: 0.2rem;
        }
      }
    `}


  ${(props) =>
    props.$htmlLineClampNoP &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: ${propOr(null, '$htmlLineClampNoP')};
      -webkit-box-orient: vertical;
      overflow: hidden;
    `}

  ${(props) =>
    props.$htmlLineClamp &&
    css`
      p {
        display: -webkit-box;
        -webkit-line-clamp: ${propOr(null, '$htmlLineClamp')};
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    `}

  ${(props) =>
    props.$newsItemText === 'hidden' &&
    css`
      transition: opacity 0s ease-in-out, max-height 0.2s ease-in-out;
      max-height: 0;
      display: flex;
    `}

  ${(props) =>
    props.$newsItemText === 'visible' &&
    css`
      transition: opacity 0.2s ease-in-out, max-height 0.2s ease-in-out;
      max-height: 13rem;
      display: inline;
    `}

  ${(props) =>
    Object.entries(devices).map(
      ([field, width]) =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        props[field] &&
        css`
          @media only screen and (max-width: ${width}) {
            font-size: ${pathOr(null, [field, '$size'], props)}${propOr('rem', '$unit')};
            line-height: ${pathOr(null, [field, '$lineHeight'], props)}${propOr('rem', '$unit')};
            font-weight: ${pathOr(null, [field, '$weight'], props)};
            text-align: ${pathOr(null, [field, '$textAlign'], props)};
            margin: ${pathOr(null, [field, '$margin'], props)};
            margin-bottom: ${pathOr(null, [field, '$marginBottom'], props)};
            margin-top: ${pathOr(null, [field, '$marginTop'], props)};
            margin-right: ${pathOr(null, [field, '$marginRight'], props)};
            margin-left: ${pathOr(null, [field, '$marginLeft'], props)};
            padding: ${pathOr(null, [field, '$padding'], props)};
            color: ${pathOr(null, [field, '$color'], props)};
            max-width: ${pathOr(null, [field, '$maxWidth'], props)};
            white-space: ${pathOr(null, [field, '$whiteSpace'])};
            gap: ${pathOr(null, [field, '$gap'])};
            display: ${pathOr(null, [field, '$display'], props)};
            width: ${pathOr(null, [field, '$width'], props)};
            height: ${pathOr(null, [field, '$height'], props)};
          }
        `,
    )}
`;
