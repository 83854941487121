import dayjs from 'dayjs';
import { DashboardCustomEvents, DashboardPeriods, DashboardSelectOption } from './models';
import { escapeRegExp } from 'lodash';

export function formatDateToString(period?: Date | string | null, template: string = 'MMMM') {
  if (!period) return undefined;
  return dayjs(period).format(template);
}

export const getDashboardPeriodsChartLabel = (date: string, period: DashboardPeriods) => {
  const dateFormats: Record<DashboardPeriods, string> = {
    [DashboardPeriods.Day]: 'L',
    [DashboardPeriods.Week]: 'L',
    [DashboardPeriods.Month]: 'MMM',
    [DashboardPeriods.Year]: 'YYYY',
  };

  return dayjs(date).format(dateFormats[period]);
};

export const convertStringDateToValidDate = (date: string | null): Date =>
  dayjs(date ?? undefined).toDate();

export function convertSelectValuesToQueryString(
  values?: DashboardSelectOption[] | null,
  separator = ';',
) {
  if (!values || values.length === 0) return undefined;
  return values.map(({ value }) => value).join(separator);
}

export function convertSelectValuesToRegExpString(
  values?: DashboardSelectOption[] | null,
  withAnchors = true,
) {
  if (!values || values.length === 0) return undefined;
  if (!withAnchors) return values.map(({ value }) => escapeRegExp(value)).join('|');
  return values.map(({ value }) => `^${escapeRegExp(value)}$`).join('|');
}

export function resetFilters() {
  const event = new Event(DashboardCustomEvents.Reset);
  window.dispatchEvent(event);
}

export function getSelectValues(items: Record<string, unknown>[], property: string) {
  const mappedItems = items.map((item) => item[property]);
  const deleteSimilar = new Set(mappedItems);

  if (!items || !mappedItems.length) return [];

  if (deleteSimilar.size)
    return Array.from(deleteSimilar)
      .filter((item) => item)
      .sort()
      .map((item) => ({
        title: item as string,
        value: item as string,
      }));

  return [];
}

export function convertMonthToWeeks(month?: Date | string | null) {
  if (!month) return undefined;

  return dayjs(month).format('MMMM');
}