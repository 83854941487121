import React from 'react';
import { DashboardTimesheetCard } from '../../../library/models';
import { Text } from '../../../atoms';
import { GridItem } from '@consta/uikit/Grid';
import { Pie } from '@consta/charts/Pie';

type Item = { type: string; value: number };
const TimesheetCard = ({ name, status, employees, approved, filled }: DashboardTimesheetCard) => {
  const data: Item[] = [
    { type: 'Работа отдела', value: 1620 },
    { type: 'Неявка', value: 100 },
    { type: 'Не Ваполнено', value: 120 },
  ];

  return (
    <GridItem style={{ alignItems: 'center' }}>
      <Text $size={2}>Январь 2024</Text>
      <Pie
        style={{
          width: '100%',
          height: '100%',
          padding: '1rem',
        }}
        data={data}
        angleField="value"
        colorField="type"
        innerRadius={0.7}
        statistic={{
          title: false,
          content: false,
        }}
      />
    </GridItem>
  );
};

export default TimesheetCard;