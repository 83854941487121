import { FC, useEffect, useRef } from 'react';

// @ts-ignore
import styles from './Table.module.sass';
import classNames from 'classnames';

export interface TableProps {
  widths?: number[];
  headers: string[];
  footerHeaders?: string[];
  content: any[];
  style?: Record<string, string>;
  className?: string;
  onNext?: () => void;
}

const Table: FC<TableProps> = ({
  widths = [],
  headers = [],
  footerHeaders = [],
  content = [],
  style = {},
  className = '',
  onNext = () => {},
}) => {
  const tableRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (tableRef) {
      tableRef?.current?.addEventListener('scroll', handleScroll);
    }
    return () => {
      tableRef?.current?.removeEventListener('scroll', handleScroll);
    };
  }, [onNext]);

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = tableRef.current as HTMLDivElement;
    if (scrollTop + clientHeight + 20 >= scrollHeight) {
      onNext();
    }
  };

  const renderColumns = (columns: any[]) =>
    columns.map((column: any, columnIndex: number) => {
      let textVal = '';
      let rowspan = 1;

      if (typeof column === 'object') {
        textVal = column?.value || '';
        rowspan = column?.rowspan || 1;
      }

      if (typeof column === 'string') textVal = column;

      return (
        <th
          key={columnIndex}
          style={{
            width: `${widths?.[columnIndex] || 100 / Math.max(1, columns.length)}%`,
          }}
          rowSpan={rowspan}
        >
          {textVal}
        </th>
      );
    });

  return (
    <div className={classNames(styles.Table, className)} style={style} ref={tableRef}>
      <table>
        <thead>
          <tr>{renderColumns(headers)}</tr>
        </thead>
        <tbody>
          {content.map((row: any, rowIndex: number) => (
            <tr key={rowIndex}>
              {row.map((cell: any, cellIndex: number) => {
                let textVal = cell;
                let rowspan = 1;

                if (typeof cell === 'object' && cell?.value) {
                  textVal = cell?.value || '';
                  rowspan = cell?.rowspan || 1;
                }
                return (
                  <td rowSpan={rowspan} key={cellIndex}>
                    {textVal}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
        {footerHeaders.length > 0 && (
          <tfoot>
            <tr>{renderColumns(footerHeaders)}</tr>
          </tfoot>
        )}
      </table>
    </div>
  );
};

export default Table;