import { pathOr, propOr } from 'ramda';
import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

import { devices, Screen } from "../../constants";

type H2Props = {
  $lineHeight?: CSSProperties['lineHeight'];
  $size?: number;
  $textTransform?: CSSProperties['textTransform'];
  $weight?: CSSProperties['fontWeight'];
  text?: string;
};

type Props = H2Props & { [key in Screen]?: H2Props };

export const H2 = styled.h2<Props>`
  font-weight: ${propOr('600', '$weight')};
  font-size: ${propOr(2, '$size')}rem;
  text-transform: ${propOr('inherit', '$textTransform')};
  line-height: ${propOr(2, '$lineHeight')}rem;
  margin: 0;

  & a {
    color: var(--color-blue);
    transition: all 0.2s ease-in-out;
    border-bottom: 1px solid var(--color-blue);

    &:hover {
      border-color: transparent;
    }
  }

  .highlight {
    background: var(--color-yellow);
  }

  ${(props) =>
    Object.entries(devices).map(
      ([field, width]) =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        props[field] &&
        css`
          @media only screen and (max-width: ${width}) {
            font-size: ${pathOr(null, [field, '$size'], props)}rem;
            line-height: ${pathOr(null, [field, '$lineHeight'], props)}rem;
          }
        `,
    )}
`;
