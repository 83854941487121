import { pathOr, propOr } from 'ramda';
import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

import { devices, Screen } from '../../constants';

interface ContentWrapperProps {
  $alignContent?: CSSProperties['alignContent'];
  $alignItems?: CSSProperties['alignItems'];
  $aspectRatio?: CSSProperties['aspectRatio'];
  $background?: CSSProperties['background'];
  $backgroundImage?: CSSProperties['backgroundImage'];
  $backgroundString?: string;
  $border?: CSSProperties['border'];
  $borderBottom?: CSSProperties['borderBottom'];
  $borderLeft?: CSSProperties['borderLeft'];
  $borderRadius?: CSSProperties['borderRadius'];
  $borderRight?: CSSProperties['borderRight'];
  $borderTop?: CSSProperties['borderTop'];
  $bottom?: CSSProperties['bottom'];
  $boxShadow?: CSSProperties['boxShadow'];
  $color?: CSSProperties['color'];
  $columnGap?: CSSProperties['columnGap'];
  $cursor?: CSSProperties['cursor'];
  $direction?: CSSProperties['direction'];
  $display?: CSSProperties['display'];
  $flex?: CSSProperties['flex'];
  $flexBasis?: CSSProperties['flexBasis'];
  $flexDirection?: CSSProperties['left'];
  $flexFlow?: CSSProperties['flexFlow'];
  $flexGrow?: CSSProperties['flexGrow'];
  $flexWrap?: CSSProperties['flexWrap'];
  $fontSize?: CSSProperties['fontSize'];
  $fontWeight?: CSSProperties['fontWeight'];
  $gap?: CSSProperties['gap'];
  $gridArea?: CSSProperties['gridArea'];
  $gridAutoColumns?: CSSProperties['gridAutoColumns'];
  $gridAutoFlow?: CSSProperties['gridAutoFlow'];
  $gridTemplateAreas?: CSSProperties['gridTemplateAreas'];
  $gridTemplateColumns?: CSSProperties['gridTemplateColumns'];
  $height?: CSSProperties['height'];
  $hoverBackground?: CSSProperties['background'];
  $hoverBorder?: CSSProperties['border'];
  $hoverColor?: CSSProperties['color'];
  $hoverPadding?: CSSProperties['padding'];
  $imageObjectFit?: CSSProperties['objectFit'];
  $incutFirstType?: boolean;
  $innerShadow?: boolean;
  $isClickable?: boolean;
  $isHidden?: boolean;
  $justifyContent?: CSSProperties['justifyContent'];
  $justifyItems?: CSSProperties['justifyItems'];
  $justifySelf?: CSSProperties['justifySelf'];
  $left?: CSSProperties['left'];
  $leftBorderPseudo?: boolean;
  $leftDotsPseudo?: boolean;
  $lineHeight?: CSSProperties['lineHeight'];
  $margin?: CSSProperties['margin'];
  $marginBottom?: CSSProperties['marginBottom'];
  $marginLeft?: CSSProperties['marginLeft'];
  $marginTop?: CSSProperties['marginTop'];
  $maxHeight?: CSSProperties['maxHeight'];
  $maxWidth?: CSSProperties['maxWidth'];
  $minHeight?: CSSProperties['minHeight'];
  $minWidth?: CSSProperties['minWidth'];
  $opacity?: CSSProperties['opacity'];
  $order?: CSSProperties['order'];
  $overflow?: CSSProperties['overflow'];
  $overflowWrap?: CSSProperties['overflowWrap'];
  $overlapShadow?: boolean;
  $overlay?: string;
  $padding?: CSSProperties['padding'];
  $paddingBottom?: CSSProperties['paddingBottom'];
  $paddingTop?: CSSProperties['paddingTop'];
  $pointerEvents?: CSSProperties['pointerEvents'];
  $position?: string;
  $quizItem?: boolean;
  $right?: CSSProperties['right'];
  $rowGap?: CSSProperties['rowGap'];
  $scrollMarginTop?: CSSProperties['scrollMarginTop'];
  $textAlign?: CSSProperties['textAlign'];
  $textOverflow?: CSSProperties['textOverflow'];
  $textTransform?: CSSProperties['textTransform'];
  $top?: CSSProperties['top'];
  $transform?: CSSProperties['transform'];
  $transition?: CSSProperties['transition'];
  $userSelect?: CSSProperties['userSelect'];
  $visibility?: CSSProperties['visibility'];
  $width?: CSSProperties['width'];
  $wordBreak?: CSSProperties['wordBreak'];
  $zIndex?: CSSProperties['zIndex'];
}

type Props = ContentWrapperProps & { [key in Screen]?: ContentWrapperProps };

export const Grid = styled.div<Props>`
  display: ${propOr('flex', '$display')};
  z-index: ${propOr(null, '$zIndex')};
  flex: ${propOr(null, '$flex')};
  flex-flow: ${propOr(null, '$flexFlow')};
  flex-grow: ${propOr(null, '$flexGrow')};
  flex-wrap: ${propOr(null, '$flexWrap')};
  flex-basis: ${propOr(null, '$flexBasis')};
  align-items: ${propOr(null, '$alignItems')};
  align-content: ${propOr(null, '$alignContent')};
  text-align: ${propOr(null, '$textAlign')};
  justify-content: ${propOr(null, '$justifyContent')};
  justify-items: ${propOr(null, '$justifyItems')};
  margin: ${propOr(null, '$margin')};
  margin-top: ${propOr(null, '$marginTop')};
  margin-bottom: ${propOr(null, '$marginBottom')};
  margin-left: ${propOr(null, '$marginLeft')};
  padding: ${propOr(null, '$padding')};
  padding-top: ${propOr(null, '$paddingTop')};
  padding-bottom: ${propOr(null, '$paddingBottom')};
  max-width: ${propOr(null, '$maxWidth')};
  max-height: ${propOr(null, '$maxHeight')};
  min-height: ${propOr(null, '$minHeight')};
  min-width: ${propOr(null, '$minWidth')};
  background: ${propOr(null, '$backgroundString')};
  background-image: ${propOr(null, '$backgroundImage')};
  color: ${propOr(null, '$color')};
  border: ${propOr(null, '$border')};
  border-radius: ${propOr(null, '$borderRadius')};
  border-top: ${propOr(null, '$borderTop')};
  border-bottom: ${propOr(null, '$borderBottom')};
  border-left: ${propOr(null, '$borderLeft')};
  border-right: ${propOr(null, '$borderRight')};
  box-shadow: ${propOr(null, '$boxShadow')};
  position: ${propOr(null, '$position')};
  bottom: ${propOr(null, '$bottom')};
  gap: ${propOr(null, '$gap')};
  column-gap: ${propOr(null, '$columnGap')};
  row-gap: ${propOr(null, '$rowGap')};
  text-overflow: ${propOr(null, '$textOverflow')};
  text-transform: ${propOr(null, '$textTransform')};
  overflow: ${propOr(null, '$overflow')};
  width: ${propOr(null, '$width')};
  height: ${propOr(null, '$height')};
  transition: ${propOr(null, '$transition')};
  transform: ${propOr(null, '$transform')};
  grid-auto-flow: ${propOr(null, '$gridAutoFlow')};
  grid-area: ${propOr(null, '$gridArea')};
  grid-template-areas: ${propOr(null, '$gridTemplateAreas')};
  grid-auto-columns: ${propOr(null, '$gridAutoColumns')};
  grid-template-columns: ${propOr(null, '$gridTemplateColumns')};
  direction: ${propOr(null, '$direction')};
  flex-direction: ${propOr(null, '$flexDirection')};
  aspect-ratio: ${propOr(null, '$aspectRatio')};
  cursor: ${propOr(null, '$cursor')};
  order: ${propOr(null, '$order')};
  top: ${propOr(null, '$top')};
  right: ${propOr(null, '$right')};
  left: ${propOr(null, '$left')};
  opacity: ${propOr(null, '$opacity')};
  font-weight: ${propOr(null, '$fontWeight')};
  font-size: ${propOr(null, '$fontSize')};
  line-height: ${propOr(null, '$lineHeight')};
  scroll-margin-top: ${propOr(null, '$scrollMarginTop')};
  justify-self: ${propOr(null, '$justifySelf')};
  user-select: ${propOr(null, '$userSelect')};
  pointer-events: ${propOr(null, '$pointerEvents')};
  visibility: ${propOr(null, '$visibility')};

  ${() => css`
    & * {
      word-break: ${propOr(null, '$wordBreak')};
      overflow-wrap: ${propOr(null, '$overflowWrap')};
    }
  `}

  ${(props) =>
    props.$isHidden &&
    css`
      display: none;
    `}

  ${(props) =>
    props.$isClickable &&
    css`
      & a {
        pointer-events: none;
        color: var(--color-white);
      }
    `}

  ${(props) =>
    props.$hoverColor &&
    css`
      &:hover {
        color: ${propOr(null, '$hoverColor')};
      }
    `}

  ${(props) =>
    props.$hoverBackground &&
    css`
      &:hover {
        background-color: ${propOr(null, '$hoverBackground')};
      }
    `}

  ${(props) =>
    props.$hoverBorder &&
    css`
      &:hover {
        border: ${propOr(null, '$hoverBorder')};
      }
    `}

  ${(props) =>
    props.$hoverPadding &&
    css`
      &:hover {
        padding: ${propOr(null, '$hoverPadding')};
      }
    `}

  ${(props) =>
    props.$leftBorderPseudo &&
    css`
      &::after {
        content: '';
        position: absolute;
        width: 4px;
        height: 100%;
        left: 0;
        top: 0;
        background-color: var(--color-red);
        border-radius: 3px 0 0 3px;
        z-index: 0;
      }
    `}

  ${(props) =>
    props.$leftDotsPseudo &&
    css`
      &::after {
        content: '';
        position: absolute;
        left: -6px;
        top: 8px;
        width: 16px;
        height: 16px;
        box-sizing: border-box;
        border-radius: 16px;
        background-color: var(--color-light-red);
        border: 3px solid var(--color-white);
        z-index: 2;
        transition:
          background-color 0.25s ease-out,
          border-color 0.25s ease-out;
      }

      &:hover {
        &:after {
          background-color: var(--color-red);
          border-color: var(--color-white);
        }
      }
    `}

  ${(props) =>
    props.$overlapShadow &&
    css`
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 2px;
        background: rgba(46, 46, 46, 0.6);
      }
    `}

  ${(props) =>
    props.$innerShadow &&
    css`
      &:hover {
        background-color: var(--color-black);
        opacity: 0.2;
      }
    `}

  ${(props) =>
    props.$overlay &&
    css`
      &::after {
        content: '';
        width: 100%;
        height: 100%;
        background: ${propOr(null, '$overlay')};
        position: absolute;
        top: 0;
        z-index: 10;
      }
    `}

  ${(props) =>
    props.$incutFirstType &&
    css`
      cursor: pointer;
      position: relative;

      .imageOverlay {
        background: rgba(255, 255, 255, 0);
        transition: all 0.2s ease-in-out;
      }

      &:hover {
        color: var(--color-blue);

        .imageOverlay {
          background: rgba(255, 255, 255, 0.1);
        }
      }
    `}

  ${(props) =>
    props.$quizItem &&
    css`
      cursor: pointer;
      position: relative;
      transition: all 0.2s ease-in-out;

      span,
      div {
        transition: all 0.2s ease-in-out;
      }

      &:hover {
        color: var(--color-blue);
        border-color: var(--color-blue);
        z-index: 5;

        span,
        div {
          border-color: var(--color-blue);
        }
      }
    `}


  ${(props) =>
    props.$imageObjectFit &&
    css`
      img {
        object-fit: ${propOr(null, '$imageObjectFit')};
      }
    `}


  ${(props) =>
    Object.entries(devices).map(
      ([field, width]) =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        props[field] &&
        css`
          @media only screen and (max-width: ${width}) {
            padding: ${pathOr(null, [field, '$padding'], props)};
            gap: ${pathOr(null, [field, '$gap'], props)};
            align-items: ${pathOr(null, [field, '$alignItems'], props)};
            flex-flow: ${pathOr(null, [field, '$flexFlow'], props)};
            flex-basis: ${pathOr(null, [field, '$flexBasis'], props)};
            justify-content: ${pathOr(null, [field, '$justifyContent'], props)};
            position: ${pathOr(null, [field, '$position'], props)};
            max-width: ${pathOr(null, [field, '$maxWidth'], props)};
            grid-area: ${pathOr(null, [field, '$gridArea'], props)};
            grid-template-areas: ${pathOr(null, [field, '$gridTemplateAreas'], props)};
            grid-template-columns: ${pathOr(null, [field, '$gridTemplateColumns'], props)};
            grid-auto-columns: ${pathOr(null, [field, '$gridAutoColumns'], props)};
            grid-template-areas: ${pathOr(null, [field, '$gridTemplateAreas'], props)};
            grid-auto-flow: ${pathOr(null, [field, '$gridAutoFlow'], props)};
            direction: ${pathOr(null, [field, '$direction'], props)};
            bottom: ${pathOr(null, [field, '$bottom'], props)};
            min-height: ${pathOr(null, [field, '$minHeight'], props)};
            min-width: ${pathOr(null, [field, '$minWidth'], props)};
            row-gap: ${pathOr(null, [field, '$rowGap'], props)};
            width: ${pathOr(null, [field, '$width'], props)};
            height: ${pathOr(null, [field, '$height'], props)};
            border-top: ${pathOr(null, [field, '$borderTop'], props)};
            border-bottom: ${pathOr(null, [field, '$borderBottom'], props)};
            border-right: ${pathOr(null, [field, '$borderRight'], props)};
            border-left: ${pathOr(null, [field, '$borderLeft'], props)};
            order: ${pathOr(null, [field, '$order'], props)};
            row-gap: ${pathOr(null, [field, '$rowGap'], props)};
            top: ${pathOr(null, [field, '$top'], props)};
            left: ${pathOr(null, [field, '$left'], props)};
            right: ${pathOr(null, [field, '$right'], props)};
            bottom: ${pathOr(null, [field, '$bottom'], props)};
            column-gap: ${pathOr(null, [field, '$columnGap'], props)};
            margin: ${pathOr(null, [field, '$margin'], props)};
            margin-top: ${pathOr(null, [field, '$marginTop'], props)};
            margin-bottom: ${pathOr(null, [field, '$marginBottom'], props)};
            margin-left: ${pathOr(null, [field, '$marginLeft'], props)};
            font-weight: ${pathOr(null, [field, '$fontWeight'], props)};
            font-size: ${pathOr(null, [field, '$fontSize'], props)};
            padding-top: ${pathOr(null, [field, '$paddingTop'], props)};
            padding-bottom: ${pathOr(null, [field, '$paddingBottom'], props)};
            pointer-events: ${pathOr(null, [field, '$pointerEvents'], props)};
            display: ${pathOr(null, [field, '$display'], props)};
            flex-direction: ${pathOr(null, [field, '$flexDirection'], props)};
            aspect-ratio: ${pathOr(null, [field, '$aspectRatio'], props)};
            transform: ${pathOr(null, [field, '$transform'], props)};
            background: ${pathOr(null, [field, '$background'], props)};
            flex-wrap: ${pathOr(null, [field, '$flexWrap'], props)};
            overflow: ${pathOr(null, [field, '$overflow'], props)};
            line-height: ${pathOr(null, [field, '$lineHeight'], props)};
            text-align: ${pathOr(null, [field, '$textAlign'], props)};
            visibility: ${pathOr(null, [field, '$visibility'], props)};
          }
        `,
    )}
`;