import { pathOr, propOr } from 'ramda';
import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

import { devices, Screen } from "../../constants";

type ComponentProps = {
  $alignItems?: CSSProperties['alignItems'];
  $display?: CSSProperties['display'];
  $flexDirection?: CSSProperties['left'];
  $flexFlow?: CSSProperties['flexFlow'];
  $flexWrap?: CSSProperties['flexWrap'];
  $height?: CSSProperties['height'];
  $justifyContent?: CSSProperties['justifyContent'];
  $padding?: CSSProperties['padding'];
  $visibility?: CSSProperties['visibility'];
  $width?: CSSProperties['width'];
  content?: string;
};

type Props = ComponentProps & { [key in Screen]?: ComponentProps };

export const Nav = styled.nav<Props>`
  align-items: ${propOr(null, '$alignItems')};
  display: ${propOr('grid', '$display')};
  justify-content: ${propOr(null, '$justifyContent')};
  flex-direction: ${propOr(null, '$flexDirection')};
  flex-flow: ${propOr(null, '$flexFlow')};
  flex-wrap: ${propOr(null, '$flexWrap')};
  width: ${propOr(null, '$width')};
  height: ${propOr(null, '$height')};
  visibility: ${propOr(null, '$visibility')};

  ${(props) =>
    Object.entries(devices).map(
      ([field, width]) =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        props[field] &&
        css`
          @media only screen and (max-width: ${width}) {
            padding: ${pathOr(null, [field, '$padding'], props)};
            align-items: ${pathOr(null, [field, '$alignItems'], props)};
            flex-flow: ${pathOr(null, [field, '$flexFlow'], props)};
            justify-content: ${pathOr(null, [field, '$justifyContent'], props)};
            display: ${pathOr(null, [field, '$display'], props)};
            flex-direction: ${pathOr(null, [field, '$flexDirection'], props)};
            flex-wrap: ${pathOr(null, [field, '$flexWrap'], props)};
            width: ${pathOr(null, [field, '$width'], props)};
            height: ${pathOr(null, [field, '$height'], props)};
            visibility: ${pathOr(null, [field, '$visibility'], props)};
          }
        `,
    )}
`;
