import { Action, action } from 'easy-peasy';
import { KeycloakInstance } from 'keycloak-js';

import { User } from '../models';

/**
 * User application state
 */

export interface UserStoreModel {
  currentUser?: User | undefined;
  setCurrentUser: Action<UserStoreModel, User>;

  token: KeycloakInstance['token'];
  setToken: Action<UserStoreModel, KeycloakInstance['token']>;
}

export const userStoreModel: UserStoreModel = {
  currentUser: undefined,
  setCurrentUser: action((state, payload) => {
    state.currentUser = payload;
  }),

  token: undefined,
  setToken: action((state, payload) => {
    state.token = payload;
  }),
};
