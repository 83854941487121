import React, { useState } from 'react';
import { CollapseGroup } from '@consta/uikit/CollapseGroup';
import { Grid, Text } from '../../../../atoms';
import dayjs from 'dayjs';
import { Checkbox } from '@consta/uikit/Checkbox';
import Table from '../../../Table';
import { Select } from '@consta/uikit/Select';
import { Switch } from '@consta/uikit/Switch';

const ScheduleDetailInfo = () => {
  const getItemLabel = (date: Date = new Date()) => (
    <Grid $gap={'1rem'}>
      <Checkbox checked={false} />
      <Grid $flexDirection={'column'} $gap={'.3rem'}>
        <Text $size={1.5}>{dayjs(date).format('D MMMM')}</Text>
        <Text>{dayjs(date).locale('ru').format('dddd').toLowerCase()}</Text>
      </Grid>
    </Grid>
  );

  const GetCostTypeSelect = () => {
    type Item = {
      label: string;
      id: number;
    };

    const items: Item[] = [
      {
        label: 'Явка',
        id: 1,
      },
      {
        label: 'Ночь',
        id: 2,
      },
      {
        label: 'ОТ',
        id: 3,
      },
      {
        label: 'ОВ',
        id: 4,
      },
      {
        label: 'РВ',
        id: 5,
      },
      {
        label: 'РВ1',
        id: 6,
      },
      {
        label: 'Больничный',
        id: 7,
      },
      {
        label: 'Выходной',
        id: 8,
      },
    ];

    const [value, setValue] = useState<Item | null>(items[0]);

    return <Select items={items} value={value} onChange={setValue} />;
  };
  const getItemTable = () => {
    const tableContent = [
      [
        <Grid $gap={'1rem'}>
          <Checkbox checked={false} />
          <Grid $flexDirection={'column'} $gap={'.3rem'}>
            <Text $size={1.5}>Будылин Петр Александрович</Text>
            <Text>Младший продавец</Text>
          </Grid>
        </Grid>,
        GetCostTypeSelect(),
        '00:00 - 00:00',
        <Switch checked={false} />,
        '0',
        '0',
        '0/40',
        '986',
      ],
    ];

    return (
      <Table
        className={'className'}
        widths={[30, 10, 10, 10, 10, 10, 10, 10]}
        headers={[
          'ФИО и должность',
          'Вид затрат',
          'Дежурство',
          'Всего часов в день',
          'Ночные часы',
          'Часы за неделю',
          'Норма за полгода',
        ]}
        content={tableContent}
      />
    );
  };

  const items = [
    {
      label: getItemLabel(),
      content: getItemTable(),
    },
    {
      label: 'Пункт два',
      content: 'Содержимое блока два',
    },
    {
      label: 'Пункт три',
      content: 'Содержимое блока три',
    },
  ];

  return <CollapseGroup items={items} iconPosition={'right'} />;
};

export default ScheduleDetailInfo;