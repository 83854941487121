import { pathOr, propOr } from 'ramda';
import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

import { devices, Screen } from "../../constants";

interface BadgeProps {
  $bordered?: boolean;
  $fontSize?: CSSProperties['fontSize'];
  $fontWeight?: CSSProperties['fontWeight'];
  $lineHeight?: CSSProperties['lineHeight'];
  $padding?: CSSProperties['padding'];
  $selected?: boolean;
  $textTransform?: CSSProperties['textTransform'];
}

type Props = BadgeProps & { [key in Screen]?: BadgeProps };

export const Badge = styled.div<Props>`
  font-weight: ${propOr('600', '$fontWeight')};
  font-size: ${propOr('24px', '$fontSize')};
  line-height: ${propOr('26px', '$lineHeight')};
  text-transform: ${propOr(null, '$textTransform')};
  padding: ${propOr('4px 14px', '$padding')};
  border-radius: 40px;
  cursor: pointer;
  color: var(--color-black);
  border: 2px solid transparent;
  transition: all 0.2s ease-in-out;
  max-width: min-content;
  white-space: nowrap;

  &:hover {
    border-color: var(--color-black);
  }

  ${(props) =>
    props.$selected &&
    css`
      color: var(--color-white);
      border-color: var(--color-black);
      background: var(--color-black);
    `}

  ${(props) =>
    props.$bordered &&
    css`
      border-color: var(--color-black);
    `}

  ${(props) =>
    Object.entries(devices).map(
      ([field, width]) =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        props[field] &&
        css`
          @media only screen and (max-width: ${width}) {
            font-size: ${pathOr(null, [field, '$fontSize'], props)};
            line-height: ${pathOr(null, [field, '$lineHeight'], props)};
            font-weight: ${pathOr(null, [field, '$weight'], props)};
            padding: ${pathOr(null, [field, '$padding'], props)};
          }
        `,
    )}
`;
