import { pathOr, propOr } from 'ramda';
import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

import { devices, Screen } from "../../constants";

interface LinkButtonProps {
  $background?: CSSProperties['background'];
  $border?: CSSProperties['border'];
  $borderRadius?: CSSProperties['borderRadius'];
  $chevron?: boolean;
  $fontSize?: CSSProperties['fontSize'];
  $height?: CSSProperties['height'];
  $padding?: CSSProperties['padding'];
  $width?: CSSProperties['width'];
}

type Props = LinkButtonProps & { [key in Screen]?: LinkButtonProps };

export const LinkButton = styled.a<Props>`
  border: ${propOr('2px solid var(--color-black)', '$border')};
  font-size: ${propOr('20px', '$padding')};
  font-weight: 500;
  line-height: 22px;
  border-radius: 20px;
  transition: all 0.2s ease-in-out;
  padding: ${propOr('6px 14px', '$padding')};
  width: ${propOr('100%', '$width')};
  width: ${propOr('100%', '$height')};
  background: ${propOr('#941F4666', '$background')};
  cursor: pointer;
  color: var(--color-black);
  background: transparent;
  display: flex;
  align-items: center;

  ${(props) =>
    Object.entries(devices).map(
      ([field, width]) =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        props[field] &&
        css`
          @media only screen and (max-width: ${width}) {
            padding: ${pathOr(null, [field, '$padding'], props)};
            font-size: ${pathOr(null, [field, '$fontSize'], props)};
          }
        `,
    )}
`;
