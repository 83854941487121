import React, { useEffect, useState } from 'react';
import { Grid } from '../../../atoms';
import { useStoreState } from '../../../library/store';
import { DashboardTimesheetCard } from '../../../library/models';
import { departmentsData } from '../../../library/mocks/department';
import TimesheetCard from './TimesheetCard';
import DashboardTitle from '../DashboardTitle';
import DashboardFilters from "../DashboardFilters";

const Timesheet: React.FC = () => {
  const { month, departments, employees } = useStoreState((state) => state.timesheet);
  const [timesheetCardItems, setTimesheetCardItems] = useState<DashboardTimesheetCard[]>([]);

  useEffect(() => {
    return setTimesheetCardItems(
      (departments && departments?.length) || (employees && employees?.length)
        ? departmentsData.filter(({ name }) =>
            departments?.map(({ title }) => title).includes(name),
          )
        : departmentsData,
    );
  }, [departmentsData, month, departments, employees]);

  return (
    <Grid className={'DashboardMain'}>
      <DashboardTitle />
      <DashboardFilters />
      <Grid className={'DashboardMain__body'}>
        {timesheetCardItems.map((item) => (
          <TimesheetCard {...item} />
        ))}
      </Grid>
    </Grid>
  );
};

export default Timesheet;