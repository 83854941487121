interface Routes {
  route: string;
  title: string;
  pageTitle?: string;
}

export const routes: Routes[] = [
  {
    route: '/',
    title: 'Главная',
  },
  {
    route: '/timesheet',
    title: 'Табель',
  },
  {
    route: '/schedule',
    title: 'расписание',
    pageTitle: 'расписание сотрудников',
  },
  {
    route: '/schedule/detail',
    title: 'расписание',
    pageTitle: 'расписание сотрудников',
  },
  {
    route: '/reports',
    title: 'Отчеты',
  },
  {
    route: '/dashboard',
    title: 'Дашборд',
  },
  {
    route: '/feedback',
    title: 'Обратная связь',
  },
];
