import { pathOr, propOr } from 'ramda';
import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

import { devices, Screen } from "../../constants";

type ComponentProps = {
  $padding?: CSSProperties['padding'];
  content?: string;
};

type Props = ComponentProps & { [key in Screen]?: ComponentProps };

export const OL = styled.ol<Props>`
  padding: ${propOr('0 0 0 2.7rem', '$padding')};
  margin: 0;

  li + li {
    margin-top: 1.33rem;

    @media only screen and (max-width: ${devices[Screen.SM]}) {
      margin-top: 2rem;
    }
  }

  ${(props) =>
    Object.entries(devices).map(
      ([field, width]) =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        props[field] &&
        css`
          @media only screen and (max-width: ${width}) {
            padding: ${pathOr(null, [field, '$padding'], props)};
          }
        `,
    )}
`;
