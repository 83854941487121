import React from 'react';
import { Grid, Text } from '../../atoms';
import { usePageTitle } from '../../hooks';
import { IconPrint } from '../../assets';

const DashboardTitle = () => {
  const title = usePageTitle();
  return (
    <Grid className={'DashboardMain__header'}>
      <Text>{title}</Text>
      <IconPrint style={{ cursor: 'pointer' }} onClick={() => window.print()} />
    </Grid>
  );
};

export default DashboardTitle;