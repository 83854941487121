import { pathOr, propOr } from 'ramda';
import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

import { devices, Screen } from "../../constants";

type H1Props = {
  $color?: string;
  $innerPMarginBlock?: CSSProperties['marginBlock'];
  $lineHeight?: CSSProperties['lineHeight'];
  $padding?: CSSProperties['padding'];
  $size?: number;
  $textTransform?: CSSProperties['textTransform'];
  $weight?: CSSProperties['fontWeight'];
  text?: string;
};

type Props = H1Props & { [key in Screen]?: H1Props };

export const H1 = styled.h1<Props>`
  color: ${propOr(null, '$color')};
  line-height: ${propOr(2.2, '$lineHeight')}rem;
  padding: ${propOr('normal', '$padding')};
  font-size: ${propOr(2.1, '$size')}rem;
  text-transform: ${propOr('uppercase', '$textTransform')};
  font-weight: ${propOr('600', '$weight')};
  margin-bottom: 0;
  margin-top: 0;

  & p {
    margin-block: ${propOr(0, '$innerPMarginBlock')};
  }

  ${(props) =>
    Object.entries(devices).map(
      ([field, width]) =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        props[field] &&
        css`
          @media only screen and (max-width: ${width}) {
            font-size: ${pathOr(null, [field, '$size'], props)}rem;
            line-height: ${pathOr(null, [field, '$lineHeight'], props)}rem;
            font-weight: ${pathOr(null, [field, '$weight'], props)};
            padding: ${pathOr(null, [field, '$padding'], props)};
          }
        `,
    )}
`;
