import React from 'react';
import { Grid, Header, Text } from '../../atoms';
import { Link, NavLink } from 'react-router-dom';
import { capitalize, kebabCase } from 'lodash';
import { IconLogo, IconNotification, IconSetting, IconUser } from '../../assets';
import styles from './AppHeader.module.sass';
import { routes } from '../../library/mocks/routes';

export const AppHeader = () => {
  return (
    <Header>
      <Grid className={styles.Header}>
        <Link className="logo" to={'/'}>
          <IconLogo />
        </Link>
        <Grid className={styles.HeaderNavLinks}>
          {routes &&
            routes.map(({ route, title }) => (
              <NavLink to={kebabCase(route)} key={route}>
                {capitalize(title)}
              </NavLink>
            ))}
        </Grid>
        <Grid className={styles.UserProfile}>
          <IconSetting />
          <IconNotification />
          <IconUser />
          <Grid className={styles.UserInfo}>
            <Text $weight={700}>Владислав</Text>
            <Text>Админинстратор</Text>
          </Grid>
        </Grid>
        <Grid className={styles.HeaderMenu}></Grid>
      </Grid>
    </Header>
  );
};