import React, { useEffect, useState } from 'react';
import { Grid } from '../../atoms';
import { DatePicker } from '@consta/uikit/DatePicker';
import { IconCalendar } from '@consta/icons/IconCalendar';
import { Combobox } from '@consta/uikit/Combobox';
import { useStoreActions, useStoreState } from '../../library/store';
import { getSelectValues } from '../../library/helpers';
import { DashboardSelectOption } from '../../library/models';
import { departmentsData } from '../../library/mocks/department';

const DashboardFilters = () => {
  const { month, departments, employees } = useStoreState((state) => state.timesheet);
  const { setMonth, setDepartments, setEmployees } = useStoreActions((state) => state.timesheet);

  const [departmentsItems, setDepartmentsItems] = useState<DashboardSelectOption[]>([]);
  const [employeesItems, setEmployeesItems] = useState<DashboardSelectOption[]>([]);

  useEffect(() => {
    setDepartmentsItems(getSelectValues(departmentsData, 'name'));

    setEmployeesItems(
      departmentsData
        .flatMap((departmentData) => departmentData?.employees)
        .filter((item) => item)
        .sort()
        .map((item) => ({
          title: item as string,
          value: item as string,
        })),
    );
  }, [departments]);


  return (
    <Grid className={'DashboardMain__filters'}>
      <Grid $alignItems={'center'}>
        <DatePicker
          className={'DatePicker'}
          type="month"
          value={month}
          onChange={setMonth}
          rightSide={IconCalendar}
        />
        <IconCalendar className={'IconCalendar'} />
      </Grid>
      <Combobox
        className={'DashboardMain__select'}
        items={departmentsItems}
        isLoading={!departmentsItems}
        value={departments}
        onChange={setDepartments}
        getItemLabel={(item) => item.title ?? ''}
        getItemKey={(item) => item.title ?? ''}
        placeholder={'Выберите отдел'}
        size="m"
        multiple
      />
      <Combobox
        className={'DashboardMain__select'}
        items={employeesItems}
        isLoading={!employeesItems}
        value={employees}
        onChange={setEmployees}
        getItemLabel={(item) => item.title ?? ''}
        getItemKey={(item) => item.title ?? ''}
        placeholder={'Выберите Сотрудника'}
        size="m"
        multiple
      />
    </Grid>
  );
};

export default DashboardFilters;