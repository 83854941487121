import { pathOr, propOr } from 'ramda';
import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';
import { devices, Screen } from '../../constants';

type HeaderProp = {
  $backgroundColor?: CSSProperties['backgroundColor'];
  $borderBottom?: CSSProperties['borderBottom'];
  $display?: CSSProperties['display'];
  $flexDirection?: CSSProperties['flexDirection'];
  $height?: CSSProperties['height'];
  $lineHeight?: CSSProperties['lineHeight'];
  $marginBottom?: CSSProperties['marginBottom'];
  $maxHeight?: CSSProperties['maxHeight'];
  $padding?: CSSProperties['padding'];
  $size?: number;
  $textTransform?: CSSProperties['textTransform'];
  $top?: CSSProperties['top'];
  $weight?: CSSProperties['fontWeight'];
  $width?: CSSProperties['width'];
};

type Props = HeaderProp & { [key in Screen]?: HeaderProp };

export const Header = styled.header<Props>`
  z-index: 20;
  position: sticky;
  top: ${propOr('0', '$top')};
  height: ${propOr('auto', '$height')};
  background-color: ${propOr(null, '$backgroundColor')};
  margin-bottom: ${propOr(null, '$marginBottom')};
  width: ${propOr(null, '$width')};
  flex-direction: ${propOr(null, '$flexDirection')};
  border-bottom: ${propOr(null, '$borderBottom')};
  display: ${propOr(null, '$display')};
  padding: ${propOr(null, '$padding')};

  ${(props) =>
    Object.entries(devices).map(
      ([field, width]) =>
        // @ts-ignore
        props[field] &&
        css`
          @media only screen and (max-width: ${width}) {
            height: ${pathOr(null, [field, '$height'], props)};
            display: ${pathOr(null, [field, '$display'], props)};
            padding: ${pathOr(null, [field, '$padding'], props)};
          }
        `,
    )}
`;