import { pathOr, propOr } from 'ramda';
import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

import { devices, Screen } from "../../constants";

type TitleProps = {
  $color?: string;
  $lineHeight?: CSSProperties['lineHeight'];
  $padding?: CSSProperties['padding'];
  $size?: number;
  $weight?: number;
};

type Props = TitleProps & { [key in Screen]?: TitleProps };

export const Title = styled.div<Props>`
  font-size: ${propOr(14, '$size')}px;
  line-height: ${propOr(15, '$lineHeight')}px;
  font-weight: ${propOr('normal', '$weight')};
  color: ${propOr(null, '$color')};
  padding: ${propOr('normal', '$padding')};

  ${(props) =>
    Object.entries(devices).map(
      ([field, width]) =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        props[field] &&
        css`
          @media only screen and (max-width: ${width}) {
            font-size: ${pathOr(null, [field, '$size'], props)}px;
            line-height: ${pathOr(null, [field, '$lineHeight'], props)}px;
            font-weight: ${pathOr(null, [field, '$weight'], props)};
            padding: ${pathOr(null, [field, '$padding'], props)};
          }
        `,
    )}
`;
