import React from "react";
import { GridItem } from "@consta/uikit/Grid";
import { DashboardScheduleCard } from "../../../library/models";
import { Grid, Text } from "../../../atoms";
import { Badge } from "@consta/uikit/Badge";
import { Pie } from "@consta/charts/Pie";
import { IconClock, IconEmployee } from "../../../assets";

type Item = { status: string; value: number };

const ScheduleCard = (
  {
    title,
    time_plan,
    employees,
    approved_employees,
    fill_percent,
  }: DashboardScheduleCard
) => {

  const renderBadge = (): JSX.Element => {
    switch (true) {
      case (fill_percent === 0):
        return (
          <Badge
            view="stroked"
            status="error"
            size="l"
            label="Ожидает"
          />
        )
      case (fill_percent === 100):
        return (
          <Badge
            view="stroked"
            status="success"
            size="l"
            label="Заполнено"
          />
        )
      default:
        return (
          <Badge
            view="stroked"
            status="warning"
            size="l"
            label="Заполнение"
          />
        )
    }
  }

  const getStatus = (): string => {
    switch (true) {
      case (fill_percent === 0):
        return 'Ожидает'
      case (fill_percent === 100):
        return 'Заполнено'
      default:
        return 'Заполнение'
    }
  }

  const data: Item[] = [
    { status: getStatus(), value: fill_percent },
    { status: 'Не заполнено', value: 100-fill_percent },
  ];

  const colorMap: { [key in Item['status']]: string } = {
    'Ожидает': '#b00b00',
    'Заполнение': '#FEB73B',
    'Заполнено': '#33A986',
    'Не заполнено': '#E5E5E7',
  };

  return (
    <GridItem style={{ alignItems: 'center', width: '25rem', padding: "1.5rem"}}>
      <Grid $justifyContent='space-between' $width='100%' $alignItems='center'>
        <Text $size={2}>{title}</Text>
        {renderBadge()}
      </Grid>
      <Grid $gap={'1rem'}>
        <Grid $gap={'1rem'} $flexDirection={'column'} $justifyContent={'center'}>
          <Grid $gap={'1rem'} $alignItems={'center'}>
            <IconEmployee style={{width:"30", height:"30"}}/>
            <Grid $flexDirection={'column'} $gap={'0.2rem'}>
              <Text $weight={700}>{`${approved_employees}/${employees} человек`}</Text>
              <Text>Подтвердили</Text>
            </Grid>
          </Grid>
          <Grid $gap={'1rem'} $alignItems={'center'}>
            <IconClock style={{width:"30", height:"30"}}/>
            <Grid $flexDirection={'column'} $gap={'0.2rem'}>
              <Text $weight={700}>{`${time_plan} ч`}</Text>
              <Text>План часов</Text>
            </Grid>
          </Grid>
        </Grid>
        <Pie
          style={{
            width: '10rem',
            height: '10rem',
            paddingTop: '1rem',
          }}
          data={data}
          angleField="value"
          colorField="status"
          innerRadius={0.75}
          color={({ status }) => colorMap[status]}
          statistic={{
            title: false,
            content: {
              customHtml: () => (
                <Text $size={2.2} $lineHeight="m">
                  {`${fill_percent}%`}
                </Text>
              ),
            },
          }}
          label={false}
          legend={false}
          interactions={[]}
          tooltip={false}
        />
      </Grid>
    </GridItem>
  );
};

export default ScheduleCard;