import React, { useEffect } from 'react';
import './styles/global.sass';
import './styles/Dashboards.sass';
import { PersistGate } from 'redux-persist/integration/react';

import { Loader } from '@consta/uikit/Loader';
import { StoreProvider } from 'easy-peasy';
import { presetGpnDefault, Theme } from '@consta/uikit/Theme';
import { persistor, store } from './library/store';
import { AppHeader, AppRouter } from './components';
import { BrowserRouter } from 'react-router-dom';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import 'dayjs/locale/ru';

function App() {
  const renderLoading = () => <Loader size="m" />;

  useEffect(() => {
    dayjs.extend(updateLocale);
    dayjs.extend(weekOfYear);
    dayjs.locale('ru');
  }, []);

  dayjs.updateLocale('ru', {
    months: [
      'января',
      'февраля',
      'марта',
      'апреля',
      'мая',
      'июня',
      'июля',
      'августа',
      'сентября',
      'октября',
      'ноября',
      'декабря',
    ],
  });

  return (
    <PersistGate loading={renderLoading()} persistor={persistor}>
      <StoreProvider store={store}>
        <Theme preset={presetGpnDefault}>
          <BrowserRouter>
            <AppHeader />
            <AppRouter />
          </BrowserRouter>
        </Theme>
      </StoreProvider>
    </PersistGate>
  );
}

export default App;