import { DashboardScheduleRow } from "../models";

export const scheduleData: DashboardScheduleRow[] = [
  {
    title: "29 - 31 июля",
    fill() { return this.deps_schedule.filter( ({fill_percent}) => fill_percent === 100 ).length},
    filling() { return this.deps_schedule.filter( ({fill_percent}) => fill_percent !== 100 && fill_percent !== 0 ).length},
    waiting() { return this.deps_schedule.filter( ({fill_percent}) => fill_percent === 0 ).length},
    deps_schedule: [
      {
        title: "Отдел 1",
        time_plan: 140,
        employees: 10,
        approved_employees: 0,
        fill_percent: 0,

      },
      {
        title: "Отдел 2",
        time_plan: 132,
        employees: 10,
        approved_employees: 0,
        fill_percent: 0,

      },
      {
        title: "Отдел 3",
        time_plan: 180,
        employees: 10,
        approved_employees: 0,
        fill_percent: 75,

      },
      {
        title: "Отдел 4",
        time_plan: 205,
        employees: 10,
        approved_employees: 10,
        fill_percent: 100,

      },
    ]
  },
  {
    title: "22 - 28 июля",
    fill() { return this.deps_schedule.filter( ({fill_percent}) => fill_percent === 100 ).length},
    filling() { return this.deps_schedule.filter( ({fill_percent}) => fill_percent !== 100 && fill_percent !== 0 ).length},
    waiting() { return this.deps_schedule.filter( ({fill_percent}) => fill_percent === 0 ).length},
    deps_schedule: [
      {
        title: "Отдел 1",
        time_plan: 140,
        employees: 10,
        approved_employees: 0,
        fill_percent: 25,

      },
      {
        title: "Отдел 2",
        time_plan: 132,
        employees: 10,
        approved_employees: 0,
        fill_percent: 50,

      },
      {
        title: "Отдел 3",
        time_plan: 180,
        employees: 10,
        approved_employees: 0,
        fill_percent: 75,

      },
      {
        title: "Отдел 4",
        time_plan: 205,
        employees: 10,
        approved_employees: 10,
        fill_percent: 100,

      },
    ]
  },
  {
    title: "15 - 21 июля",
    fill() { return this.deps_schedule.filter( ({fill_percent}) => fill_percent === 100 ).length},
    filling() { return this.deps_schedule.filter( ({fill_percent}) => fill_percent !== 100 && fill_percent !== 0 ).length},
    waiting() { return this.deps_schedule.filter( ({fill_percent}) => fill_percent === 0 ).length},
    deps_schedule: [
      {
        title: "Отдел 1",
        time_plan: 140,
        employees: 10,
        approved_employees: 0,
        fill_percent: 0,

      },
      {
        title: "Отдел 2",
        time_plan: 132,
        employees: 10,
        approved_employees: 0,
        fill_percent: 25,

      },
      {
        title: "Отдел 3",
        time_plan: 180,
        employees: 10,
        approved_employees: 0,
        fill_percent: 75,

      },
      {
        title: "Отдел 4",
        time_plan: 205,
        employees: 10,
        approved_employees: 10,
        fill_percent: 100,

      },
    ]
  },
]