import React, { useState } from 'react';
import { Grid } from '../../../../atoms';
import DashboardTitle from '../../DashboardTitle';
import classNames from 'classnames';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@consta/uikit/Button';
import ScheduleDetailFilters from './ScheduleDetailFilters';
import { Tabs } from '@consta/uikit/Tabs';
import styles from './ScheduleDetail.module.sass';
import ScheduleDetailInfo from './ScheduleDetailInfo';
import ScheduleDetailPeriodInfo from './ScheduleDetailPeriodInfo';
import ScheduleDetailTableInfo from './ScheduleDetailTableInfo';

export enum ScheduleDetailTabs {
  Info = 'Данные',
  Period = 'Период',
  Table = 'Таблица',
}

const ScheduleDetail: React.FC = () => {
  const tabItems: string[] = ['Данные', 'Период', 'Таблица'];
  const [currentTab, setCurrentTab] = useState(tabItems[0]);

  const navigate = useNavigate();

  const renderTab = () => {
    const scheduleDetailTabs: Record<ScheduleDetailTabs, () => JSX.Element> = {
      [ScheduleDetailTabs.Info]() {
        return <ScheduleDetailInfo />;
      },
      [ScheduleDetailTabs.Period]() {
        return <ScheduleDetailPeriodInfo />;
      },
      [ScheduleDetailTabs.Table]() {
        return <ScheduleDetailTableInfo />;
      },
    };

    return (scheduleDetailTabs as any)[currentTab]();
  };

  return (
    <Grid className={'DashboardMain'}>
      <DashboardTitle />
      <ScheduleDetailFilters />
      <Tabs
        items={tabItems}
        value={currentTab}
        onChange={setCurrentTab}
        getItemLabel={(item) => item}
      />
      <Grid className={classNames(styles.ScheduleDetail__body, 'flex-column')}>{renderTab()}</Grid>
      <Grid className="footer" $alignItems={'center'} $justifyContent={'flex-end'} $gap={'1.5rem'}>
        <Button
          label="Назад"
          form="round"
          view="secondary"
          className="red"
          onClick={() => navigate(-1)}
          style={{ margin: 'auto auto auto 0' }}
        />
        <Link to={'/schedule/detail'}>
          <Button label="Копировать  расписание" view={'secondary'} form="round" />
        </Link>
        <Link to={'/schedule/detail'}>
          <Button label="Сохранить в черновик" view={'secondary'} form="round" />
        </Link>
        <Link to={'/schedule/detail'}>
          <Button label="Сохранить и уведомить сотрудников" form="round" />
        </Link>
      </Grid>
    </Grid>
  );
};

export default ScheduleDetail;