export interface UserDepartment {
  id: number;
  department: string;
}

export const userDepartment = {
  id: 1,
  department: '«Стадион», г. Москва, ул. Большая стадионная, д. 15',
};

export const departmentsData = [
  {
    name: 'Отдел «Колдунство какое-то»',
    status: 'Согласовано',
    employees: [
      'Гарри Поттер',
      'Гермиона Грейнджер',
      'Рон Уизли',
      'Драко Малфой',
      'Альбус Дамблдор',
      'Минерва Макгонагалл',
      'Северус Снегг (Снейп)',
      'Рубеус Хагрид',
      'Невилл Долгопупс',
      'Беллатриса Лестрейндж-',
    ],
    approved: 3 / 10,
    filled: 30,
  },
  {
    name: 'Отдел «Охота на охоту»',
    status: 'Ожидает заполнения',
    employees: [
      'Сидоров Кирилл Константинович',
      'Иванов Геннадий',
      'Зураб Климентьевич Майсурадашвилиберидзерудзи',
      'Сафонов Ярослав Матвеевич',
      'Генадий Иванов',
      'Вася Петров',
      'Петр Васильев Васильевич',
      'Михаил Александрович Барбоскин',
    ],
    approved: 0 / 7,
    filled: 0,
  },
  {
    name: 'Отдел «Зима вже близенько»',
    status: 'Этап согласования',
    employees: [
      'Эддард «Нед» Старк',
      'Роберт Баратеон',
      'Джейме Ланнистер',
      'Серсея Ланнистер',
      'Дейенерис Таргариен',
      'Джон Сноу',
      'Робб Старк',
      'Тирион Ланнистер',
    ],
    approved: 3 / 4,
    filled: 100,
  },
  {
    name: 'Отдел «Приключение на 20 минут»',
    status: 'Этап согласования',
    employees: [
      'Рик Санчес',
      'Морти Смит',
      'Джерри Смит',
      'Саммер Смит',
      'Бет Смит',
      'Птичья личность',
      'Уэйн "Мистер Жопосранчик"',
      'Мистер Голденфольд',
    ],
    approved: 6 / 8,
    filled: 100,
  },
  {
    name: 'Отдел «Денег нет но вы держитесь»',
    status: 'Согласовано',
    employees: [
      'Бернар Арно',
      'Илон Маск',
      'Джефф Безос',
      'Ларри Эллисон',
      'Уоррен Баффет',
      'Билл Гейтс',
      'Майкл Блумберг',
      'Карлос Слим Элу',
      'Мукеш Амбани',
      'Стив Балмер',
      'Кукуруз Де ла Круз',
      'Родриго Лопез',
      'Оливия Кашканеро',
    ],
    approved: 12 / 12,
    filled: 100,
  },
];
