import React from 'react';
import PageNotFound from '../Dashboards/PageNotFound/PageNotFound';
import { Route, Routes } from 'react-router-dom';
import Timesheet from '../Dashboards/Timesheet/Timesheet';
import Schedule from '../Dashboards/Schedule/Schedule';
import ScheduleDetail from '../Dashboards/Schedule/Detail/ScheduleDetail';

export const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Schedule />} />
      <Route path="/timesheet" element={<Timesheet />} />
      <Route path="/schedule" element={<Schedule />} />
      <Route path="/schedule/detail" element={<ScheduleDetail />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};