export enum Screen {
  XS = '$xs',
  SM = '$sm',
  MD = '$md',
  LG = '$lg',
  XL = '$xl',
  XXL = '$xxl',
}

export const devices = {
  [Screen.XXL]: '1920px',
  [Screen.XL]: '1440px',
  [Screen.LG]: '1024px',
  [Screen.MD]: '768px',
  [Screen.SM]: '576px',
  [Screen.XS]: '375px',
};
