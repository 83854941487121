import { DashboardSelectOption } from '../../models';
import { action, Action } from 'easy-peasy';

export interface TimesheetModel {
  isFiltersActive: boolean;

  month: Date | null;
  week: number | null;
  period: [Date?, Date?] | null;
  employees?: DashboardSelectOption[] | null;
  departments?: DashboardSelectOption[] | null;

  setFiltersActive: Action<TimesheetModel, boolean>;
  setMonth: Action<TimesheetModel, Date | null>;
  setWeek: Action<TimesheetModel, number | null>;
  setPeriod: Action<TimesheetModel, [Date?, Date?] | null>;
  setEmployees: Action<TimesheetModel, DashboardSelectOption[] | null>;
  setDepartments: Action<TimesheetModel, DashboardSelectOption[] | null>;

  clearAllFilters: Action<TimesheetModel>;
}

export const timesheetModel: TimesheetModel = {
  isFiltersActive: false,

  week: null,
  month: null,
  period: null,
  employees: null,
  departments: null,

  setFiltersActive: action((state, payload) => {
    state.isFiltersActive = payload;
  }),

  setMonth: action((state, payload) => {
    state.month = payload;
    state.isFiltersActive = true;
  }),

  setWeek: action((state, payload) => {
    state.week = payload;
    state.isFiltersActive = true;
  }),

  setPeriod: action((state, payload) => {
    state.period = payload;
    state.isFiltersActive = true;
  }),

  setEmployees: action((state, payload) => {
    state.employees = payload;
    state.isFiltersActive = true;
  }),

  setDepartments: action((state, payload) => {
    state.departments = payload;
    state.isFiltersActive = true;
  }),

  clearAllFilters: action((state) => {
    state.period = null;
    state.employees = null;
    state.departments = null;

    state.isFiltersActive = false;
  }),
};