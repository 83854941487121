import { pathOr, propOr } from 'ramda';
import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

import { devices, Screen } from "../../constants";

type H3Props = {
  $lineHeight?: CSSProperties['lineHeight'];
  $padding?: CSSProperties['padding'];
  $size?: number;
  $textTransform?: CSSProperties['textTransform'];
  $weight?: CSSProperties['fontWeight'];
  text?: string;
};

type Props = H3Props & { [key in Screen]?: H3Props };

export const H3 = styled.h3<Props>`
  font-weight: ${propOr('600', '$weight')};
  font-size: ${propOr(1.5, '$size')}rem;
  line-height: ${propOr(2, '$lineHeight')}rem;
  padding: ${propOr('normal', '$padding')};
  text-transform: ${propOr('inherit', '$textTransform')};
  margin: 0;

  & a:after {
    margin-top: -2px;
    height: 2px;
  }
  }

  .highlight {
    background: var(--color-yellow);
  }

  ${(props) =>
    Object.entries(devices).map(
      ([field, width]) =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        props[field] &&
        css`
          @media only screen and (max-width: ${width}) {
            font-size: ${pathOr(null, [field, '$size'], props)}rem;
            line-height: ${pathOr(null, [field, '$lineHeight'], props)}rem;
          }
        `,
    )}
`;
