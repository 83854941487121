import { pathOr, propOr } from 'ramda';
import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

import { devices, Screen } from "../../constants";

type ComponentProps = {
  $fontSize?: CSSProperties['fontSize'];
  $padding?: CSSProperties['padding'];
  content?: string;
};

type Props = ComponentProps & { [key in Screen]?: ComponentProps };

export const LI = styled.li<Props>`
  padding: ${propOr('0 0 0 1.35rem', '$padding')};
  font-size: ${propOr(null, '$fontSize')};
  position: relative;

  ${(props) =>
    Object.entries(devices).map(
      ([field, width]) =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        props[field] &&
        css`
          @media only screen and (max-width: ${width}) {
            padding: ${pathOr(null, [field, '$padding'], props)};
            font-size: ${pathOr(null, [field, '$fontSize'], props)};
          }
        `,
    )}
`;
