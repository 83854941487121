import React from 'react';
import { Grid, Text } from '../../../atoms';
import { ResponsesImageEmptyPockets } from '@consta/uikit/ResponsesEmptyPockets';

const PageNotFound = () => {
  return (
    <Grid
      $width={'100%'}
      $height={'90vh'}
      $flexDirection={'column'}
      $alignItems={'center'}
      $justifyContent={'center'}
      $gap={'1rem'}
    >
      <ResponsesImageEmptyPockets style={{ width: '30rem' }} title="Страница еще в не готова" />
      <Text $size={1.5} $maxWidth={'30rem'} $lineHeight={1.8} $textAlign={'center'} $weight={600}>
        Страница еще в не готова
      </Text>
      <Text $size={1.2}>Но очень скоро здесь появится что-то отличное!</Text>
    </Grid>
  );
};

export default PageNotFound;