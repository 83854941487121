import React from 'react';
import { Grid } from '../../../atoms';
import DashboardTitle from '../DashboardTitle';
import { scheduleData } from '../../../library/mocks/schedule';
import classNames from 'classnames';
import ScheduleRow from './ScheduleRow';
import DashboardFilters from '../DashboardFilters';
import { Link } from 'react-router-dom';
import { Button } from '@consta/uikit/Button';

const Schedule: React.FC = () => {
  return (
    <Grid className={'DashboardMain'}>
      <DashboardTitle />
      <DashboardFilters />
      <Grid className={classNames('DashboardMain__body', 'flex-column')}>
        {scheduleData.map((schedule, index) => (
          <ScheduleRow key={`${schedule.title}${index}`} {...schedule} />
        ))}
      </Grid>
      <Grid className="footer" $alignItems={'center'} $justifyContent={'flex-end'}>
        <Link to={'/schedule/detail'}>
          <Button label="Перейти" form="round" />
        </Link>
      </Grid>
    </Grid>
  );
};

export default Schedule;