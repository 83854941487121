import React, { useEffect, useState } from 'react';
import { Grid, Text } from '../../../../atoms';
import { DatePicker } from '@consta/uikit/DatePicker';
import { IconCalendar } from '@consta/icons/IconCalendar';
import { Combobox } from '@consta/uikit/Combobox';
import { useStoreActions, useStoreState } from '../../../../library/store';
import { DashboardSelectOption } from '../../../../library/models';
import { getSelectValues } from '../../../../library/helpers';
import { departmentsData } from '../../../../library/mocks/department';
import styles from './ScheduleDetail.module.sass';
import classNames from 'classnames';
import { ProgressLine } from '@consta/uikit/ProgressLine';
import dayjs from 'dayjs';

const ScheduleDetailFilters = () => {
  const { month, departments, employees } = useStoreState((state) => state.timesheet);
  const { setMonth, setDepartments, setEmployees } = useStoreActions((state) => state.timesheet);

  const [departmentsItems, setDepartmentsItems] = useState<DashboardSelectOption[]>([]);
  const [employeesItems, setEmployeesItems] = useState<DashboardSelectOption[]>([]);

  useEffect(() => {
    setDepartmentsItems(getSelectValues(departmentsData, 'name'));

    setEmployeesItems(
      departmentsData
        .flatMap((departmentData) => departmentData?.employees)
        .filter((item) => item)
        .sort()
        .map((item) => ({
          title: item as string,
          value: item as string,
        })),
    );
  }, [departments]);

  return (
    <Grid className={styles.ScheduleDetail__filters}>
      <Grid className={classNames('DashboardMain__filters', styles.Datepicker)}>
        <Grid $alignItems={'center'}>
          <DatePicker
            className={'DatePicker'}
            type="month"
            value={month}
            onChange={setMonth}
            rightSide={IconCalendar}
          />
          <IconCalendar className={'IconCalendar'} />
        </Grid>
        <Text>{dayjs().week()}</Text>
      </Grid>
      <Grid className={classNames('DashboardMain__filters', styles.Combobox)}>
        <Combobox
          className={'DashboardMain__select'}
          items={departmentsItems}
          isLoading={!departmentsItems}
          value={departments}
          onChange={setDepartments}
          getItemLabel={(item) => item.title ?? ''}
          getItemKey={(item) => item.title ?? ''}
          placeholder={'Выберите отдел'}
          size="m"
          multiple
        />
        <Combobox
          className={'DashboardMain__select'}
          items={employeesItems}
          isLoading={!employeesItems}
          value={employees}
          onChange={setEmployees}
          getItemLabel={(item) => item.title ?? ''}
          getItemKey={(item) => item.title ?? ''}
          placeholder={'Выберите Сотрудника'}
          size="m"
          multiple
        />
      </Grid>
      <Grid className={classNames('DashboardMain__filters', styles.ProgressLine)}>
        <Text $size={1.5}>Отдел «‎Спортивная охота»</Text>
        <Text $size={1.1}>15 – 21 июля</Text>
        <Grid className={'flex-column'} $margin={'auto auto 0'} $width={'100%'} $gap={'0.5rem'}>
          <Grid $justifyContent={'space-between'}>
            <Text $size={1.3} $color={'#33A986'}>
              0 ч.
            </Text>
            <Text $size={1.3}>140 ч.</Text>
          </Grid>
          <ProgressLine value={75} />
          <Text $size={1} $color={'var(--color-gray-dark)'}>
            0% от плана заполнено
          </Text>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ScheduleDetailFilters;