import React from 'react';
import { DashboardScheduleRow } from '../../../library/models';
import { Grid, Text } from '../../../atoms';
import ScheduleCard from './ScheduleCard';
import { BadgePropStatus } from '@consta/uikit/Badge';
import { BadgeGroup } from '@consta/uikit/BadgeGroup';
import { convertMonthToWeeks } from '../../../library/helpers';

const ScheduleRow = (schedule: DashboardScheduleRow) => {
  type BadgeItem = {
    label: string;
    color: BadgePropStatus;
  };

  const badgeItems: BadgeItem[] = [
    {
      label: schedule.fill().toString(),
      color: 'success',
    },
    {
      label: schedule.filling().toString(),
      color: 'warning',
    },
    {
      label: schedule.waiting().toString(),
      color: 'error',
    },
  ];
  const renderBadgeGroup = (items: BadgeItem[]) => {
    return (
      <BadgeGroup
        items={[...items]}
        form="round"
        size="m"
        getItemKey={(item) => item.label}
        getItemLabel={(item) => item.label}
        getItemStatus={(item) => item.color}
        getItemView={() => 'stroked'}
      />
    );
  };

  return (
    <Grid className={'flex-column'} $gap={'2rem'}>
      <Grid $gap={'1rem'}>
        <Text $size={2}>{schedule.title}</Text>
        <Text $size={2}>{convertMonthToWeeks(new Date())}</Text>
        {renderBadgeGroup(badgeItems)}
      </Grid>
      <Grid $gap={'2rem'}>
        {schedule.deps_schedule.map((deps_schedule) => (
          <ScheduleCard {...deps_schedule} />
        ))}
      </Grid>
    </Grid>
  );
};

export default ScheduleRow;